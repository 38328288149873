"use client";

import { getPairMappings } from "@/db/live";
import { PairMapping } from "@/models/live";
import { useEffect, useState } from "react";

export default function Home() {
  const [backtestLinks, setBacktestLinks] = useState<string[]>([]);
  const [liveLinks, setLiveLinks] = useState<string[]>([]);

  useEffect(() => {
    const generateLinks = async () => {
        const mappings: PairMapping[] = await getPairMappings();
        const generatedBacktestLinks = mappings.map((mapping) => 
        `/backtest/${mapping.pairAddress}`
        );
        setBacktestLinks(generatedBacktestLinks);
        const generatedLiveLinks = mappings.map((mapping) => 
          `/live/${mapping.pairAddress}`
          );
          setLiveLinks(generatedLiveLinks);
    }
    generateLinks();
  }, []);

  return (
    <div className="w-full">
      {/* <!-- Project Description Section --> */}
      <section className="flex flex-col w-full justify-center mt-6">
        <div className="flex flex-col w-full justify-center">
          <div className="flex flex-row w-full justify-center">
              <h1 className="md:text-2xl text-2xl font-normal text-base-content">
                View
              </h1>
          </div>
          <div className="flex flex-row w-full justify-center">    
            <h2>your configured Pairs <span className="text-green-400">live</span> or <span className="text-yellow-400">backtested</span></h2>
          </div>
        </div>
      </section>

      {/* <!-- List Section --> */}
      {liveLinks.length !== 0 && backtestLinks.length !== 0 && (
        <>
        <section className="container mx-auto p-3 text-center">
          <div><span className="font-bold">Live Views:</span></div>  
          <ul>
            {liveLinks.map((link, index) => (
              <li key={index} className="my-2">
                  <label>Live List {index}: </label>
                  <a href={link} className='text-blue-400'>
                      {link}
                  </a>
              </li>
            ))}
          </ul>
        </section>

        
        <section className="container mx-auto p-3 text-center">
        <div><span className="font-bold">Backtest:</span></div> 
          <ul>
            {backtestLinks.map((link, index) => (
              <li key={index} className="my-2">
                  <label>Backtest List {index}: </label>
                  <a href={link} className='text-blue-400'>
                      {link}
                  </a>
              </li>
            ))}
          </ul>
        </section> 
      </>
      )}

      

      {/* <!-- Footer --> */}
      <footer className="footer footer-center p-4 text-base-content">
        <div>
          <p>bot frontend © 2024. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}
